import { mergeDeep } from 'theme/helpers/merge-deep';
import { metaCore } from 'theme/meta/base';
import i18n from '@vue-storefront/i18n'
import { isServer } from '@vue-storefront/core/helpers'

const mergeArray = (a, b) => {
  const aArray = a || []
  const bArray = b || []

  return [...aArray, ...bArray].filter((value, index, self) =>
    index === self.findIndex((t) => (
      t.rel === value.rel && t.href === value.href
    ))
  )
}

export const mergeMeta = (meta) => {
  const links = meta.link || []
  const scripts = meta.script || []
  const baseUrl = isServer ? process.env.VUE_APP_BASE_URL : window.location.origin
  const currentPath = isServer ? meta.currentPath : window.location.pathname

  // Безпечно очищаємо шлях від префіксу мови
  const cleanPath = currentPath
    ? currentPath
      .replace(/^\/ru\/?/, '/') // Видаляємо /ru/ або /ru
      .replace(/^\/+/, '/') // Видаляємо зайві слеші на початку
      .replace(/\/+$/, '') // Видаляємо зайві слеші в кінці
    : '/'

  const result: any = metaCore()

  mergeDeep(
    result,
    meta
  )

  result.htmlAttrs = {
    ...result.htmlAttrs,
    lang: i18n.locale,
    prefix: 'og:http://ogp.me/ns#'
  }

  // Формуємо hreflang теги в залежності від поточної мови
  const hreflangLinks = i18n.locale === 'ru-RU'
    ? [
      {
        vmid: 'hreflang-ru',
        rel: 'alternate',
        hreflang: 'ru-RU',
        href: `${baseUrl}/ru${cleanPath}`
      },
      {
        vmid: 'hreflang-uk',
        rel: 'alternate',
        hreflang: 'uk-UA',
        href: `${baseUrl}${cleanPath}`
      },
      {
        vmid: 'hreflang-default',
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${baseUrl}/ru${cleanPath}`
      }
    ]
    : [
      {
        vmid: 'hreflang-uk',
        rel: 'alternate',
        hreflang: 'uk-UA',
        href: `${baseUrl}${cleanPath}`
      },
      {
        vmid: 'hreflang-ru',
        rel: 'alternate',
        hreflang: 'ru-RU',
        href: `${baseUrl}/ru${cleanPath}`
      },
      {
        vmid: 'hreflang-default',
        rel: 'alternate',
        hreflang: 'x-default',
        href: `${baseUrl}${cleanPath}`
      }
    ]

  // Об'єднуємо звичайні посилання та додаємо hreflang теги
  result.link = [...mergeArray(result.link, links), ...hreflangLinks]
  result.script = mergeArray(result.script, scripts)

  result.meta = Object.keys(result.meta)
    .map(k => ({ vmid: k, ...result.meta[k] }))

  return result
}
